import "./polyfill.js";
import $ from "jquery";
import headerMenuTop from "./header_footer/HeaderMenuTop";
import GlobalNavi from "./header_footer/GlobalNavi";
import SpGlobalNavi from "./header_footer/SpGlobalNavi";
import gotoTop from "./header_footer/GotoTop";
import anchorLink from "./header_footer/AnchorLink";
import headerSearchForm from "./header_footer/HeaderSearchForm";
import footerMenu from "./header_footer/FooterMenu";

$(() => {

  const ua = window.navigator.userAgent.toUpperCase();
  if (ua.indexOf('MSIE') === -1 && ua.indexOf('TRIDENT') === -1) {
  } else {
    // ie11
    document.body.classList.add('is-ie11');
  }

  new headerMenuTop();
  new GlobalNavi();
  new SpGlobalNavi();

  if (document.getElementsByClassName('js-footerMenu').length) {
    new footerMenu('js-footerMenu');
  }

  if (document.getElementsByClassName('js-gotoTop').length) {
    new gotoTop();
  }

  if (document.getElementsByClassName('js-anchorLink').length) {
    new anchorLink();
  }

  if (document.getElementsByClassName('js-headerSearchForm').length) {
    new headerSearchForm();
  }
});

