import _ from "underscore";
import UA from "../lib/UA";
import SitePath from "../lib/SitePath";
import GetJson from "../lib/GetJson";

export default class FooterMenu {
  constructor(container) {
    this.target = document.getElementsByClassName(container)[0];
    this.base = this.target.getAttribute("data-base") || "";
    this.bgElm = document.getElementsByClassName("js-footerCategoryBg")[0];
    this.ua = new UA();
    this.sitePath = new SitePath();
    this.getJson = new GetJson();
    this.jsonFile = this.sitePath.getJsonPath();
    this.init();
  }

  init() {
    this.initList();
  }

  bgBindEvents() {
    const container = document.getElementsByClassName('js-footerBg')[0];
    if (!container) return false;

    const triggerElm = container.getElementsByClassName('js-footerBgTrigger');

    if (triggerElm.length) {
      Array.prototype.forEach.call(triggerElm, (elm, index) => {
        elm.addEventListener('mouseenter', e => {
          this.changeBg(index + 1);
        });
        elm.addEventListener('mouseleave', e => {
          this.resetBg();
        });
      });
    }
  }

  initList() {
    // jsonFile 取得
    const jsonEvent = this.getJson.get(this.jsonFile);
    jsonEvent.done(data => {
      if (this.base == "person") {
        // 個人カテゴリ
        const list = this.createList(data.person.child);
        if (list) {
          this.target.appendChild(list);
        }
      } else if (this.base == "company") {
        // 個人事業主
        const list = this.createList(data.company.child);
        if (list) {
          this.target.appendChild(list);
        }
      } else if (this.base == "ir") {
        // 投資家
        const list = this.createList(data.ir.child);
        if (list) {
          this.target.appendChild(list);
        }
      } else if (this.base == "about") {
        // 企業情報
        const list = this.createList(data.about.child);
        if (list) {
          this.target.appendChild(list);
        }
      } else if (this.base == "recruit") {
        // 採用のご案内
        const list = this.createList(data.recruit.child);
        if (list) {
          this.target.appendChild(list);
        }
      } else {
        // 共有
        const list = this.createList(data);
        if (list) {
          this.target.appendChild(list);
        }
      }

      if (this.bgElm) {
        if (this.base == "person") {
          const bgElm = this.createBg(data.person.child);
          if (bgElm) {
            this.bgElm.appendChild(bgElm);
          }
        } else if (this.base == "company") {
          const bgElm = this.createBg(data.company.child);
          if (bgElm) {
            this.bgElm.appendChild(bgElm);
          }
        } else if (this.base == "ir") {
          const bgElm = this.createBg(data.ir.child);
          if (bgElm) {
            this.bgElm.appendChild(bgElm);
          }
        } else if (this.base == "about") {
          const bgElm = this.createBg(data.about.child);
          if (bgElm) {
            this.bgElm.appendChild(bgElm);
          }
        } else if (this.base == "recruit") {
          const bgElm = this.createBg(data.recruit.child);
          if (bgElm) {
            this.bgElm.appendChild(bgElm);
          }
        } else {
          const bgElm = this.createBg(data);
          if (bgElm) {
            this.bgElm.appendChild(bgElm);
          }
        }

        if (this.ua.device() == "other") {
          this.bgBindEvents();
        }
      }
    });
  }

  createList(data) {
    const fragment = document.createDocumentFragment();

    _.each(data, item => {
      if (item.disabled == "") {
        const categoryContainer = document.createElement("div");
        categoryContainer.classList.add("footerCategoryLink__category");
        categoryContainer.classList.add("js-footerBgTrigger");

        categoryContainer.appendChild(this.createListHeader(item));
        categoryContainer.appendChild(this.createListContents(item));

        fragment.appendChild(categoryContainer);
      }
    });

    return fragment;
  }

  createListHeader(item) {
    const elm = document.createElement("p");
    elm.classList.add("footerCategoryLink__heading");

    if (item.url) {
      const a = document.createElement("a");
      a.href = item.url;
      a.textContent = item.name;

      if (item.blank) {
        a.classList.add("footerCategoryLink__blank--mr0");
        a.target = "_blank";
      } else if (item.login) {
        a.classList.add("footerCategoryLink__ex--mr0");
        a.target = "_blank";
      }
      if (item.pdf) {
        a.classList.add("footerCategoryLink__pdf--mr0");
        a.target = "_blank";
      }

      elm.appendChild(a);
    } else {
      const span = document.createElement("span");
      span.textContent = item.name;

      elm.appendChild(span);
    }



    return elm;
  }

  createListContents(item) {
    const fragment = document.createDocumentFragment();

    if (item.child.length) {
      const ul = document.createElement("ul");

      _.each(item.child, item2 => {
        if (item2.disabled == "") {
          const li = document.createElement("li");
          const a = document.createElement("a");
          a.href = item2.url;
          a.textContent = item2.name;
          if (item2.blank) {
            a.classList.add("footerCategoryLink__blank");
            a.target = "_blank";
          } else if (item2.login) {
            a.classList.add("footerCategoryLink__ex");
            a.target = "_blank";
          }
          if (item2.pdf) {
            a.classList.add("footerCategoryLink__pdf");
            a.target = "_blank";
          }
          li.appendChild(a);
          ul.appendChild(li);
        }
      });

      fragment.appendChild(ul);
    }

    return fragment;
  }

  createBg(data) {
    const fragment = document.createDocumentFragment();

    const elmBase = document.createElement("div");
    elmBase.classList.add("footerCategoryBg__bgItem");
    elmBase.classList.add("js-footerBgImage");
    elmBase.classList.add("-active");

    fragment.appendChild(elmBase);

    _.each(data, item => {
      const elm = document.createElement("div");
      elm.classList.add("footerCategoryBg__bgItem");
      elm.classList.add("js-footerBgImage");

      if (item.pcFooterBg) {
        elm.style.backgroundImage = "url(" + item.pcFooterBg + ")";
        const img = document.createElement("img");
        img.src = item.pcFooterBg;
        img.alt = "";
        elm.appendChild(img);
      }

      fragment.appendChild(elm);
    });

    return fragment;
  }

  resetBg() {
    const container = document.getElementsByClassName('js-footerBg')[0];
    const bgElm = container.getElementsByClassName('js-footerBgImage');

    if (bgElm.length) {
      Array.prototype.forEach.call(bgElm, (elm, i) => {
        if (i == 0) {
          elm.classList.add('-active');
        } else {
          elm.classList.remove('-active');
        }
      });
    }
  }

  changeBg(index) {
    const container = document.getElementsByClassName('js-footerBg')[0];
    const triggerElm = container.getElementsByClassName('js-footerBgTrigger');
    const bgElm = container.getElementsByClassName('js-footerBgImage');

    if (triggerElm.length) {
      Array.prototype.forEach.call(triggerElm, (elm, i) => {
        if (i == index) {
          elm.classList.add('-active');
        } else {
          elm.classList.remove('-active');
        }
      });
    }

    if (bgElm.length) {
      Array.prototype.forEach.call(bgElm, (elm, i) => {
        if (i == index) {
          elm.classList.add('-active');
        } else {
          elm.classList.remove('-active');
        }
      });
    }
  }
}
