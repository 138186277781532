import $ from "jquery";
import _ from "underscore";
import UA from "../lib/UA";
import IsPassiveSupport from "../lib/IsPassiveSupport";
import SitePath from "../lib/SitePath";
import GetJson from "../lib/GetJson";

export default class SpGlobalNavi {
  constructor() {
    this.container = document.getElementsByClassName('js-header')[0];
    this.headerTop = this.container.getElementsByClassName('js-headerTop')[0];
    this.globalNavi = document.getElementsByClassName('js-spGlobalNavi')[0];
    this.openFlg = false;
    this.stopFlg = false;
    this.timeout;
    this.ua = new UA();
    this.sitePath = new SitePath();
    this.getJson = new GetJson();
    this.jsonFile = this.sitePath.getJsonPath();
    this.basePath;
    this.menuOpenFirstTimeFlg = false;
    this.init();
  }

  init() {
    this.bindEvents();
    this.initNaviList();
  }

  bindEvents() {
    const self = this;
    const isPassive = new IsPassiveSupport();
    const spMenuBtn = this.container.getElementsByClassName('js-spMenuBtn')[0];
    const spLoginBtn = this.container.getElementsByClassName('js-spLoginBtn')[0];
    const spOverlayElm = this.container.getElementsByClassName('js-spGlobalNaviOverlay')[0];

    this.debouncedOnSpMenuBtn = _.debounce(() => {
      if (!this.stopFlg) {
        this.stopFlg = true;
        if (spMenuBtn.classList.contains('-active')) {
          this.stopFlg = true;
          this.closeSpMenu();
          this.closeSpLoginMenu();
        } else {
          this.openSpMenu();
        }
      }
    });
    if (spMenuBtn) {
      spMenuBtn.addEventListener('click', e => {
        e.preventDefault();
        e.stopPropagation();
        this.debouncedOnSpMenuBtn();
      });
    }

    this.debouncedOnSpNaviMenu = _.debounce(elm => {
      if (elm.classList.contains('-active')) {
        this.closeSpNaviMenu(elm);
      } else {
        this.openSpNaviMenu(elm);
      }
    });

    this.debouncedOnSpLoginBtn = _.debounce(() => {
      if (!this.stopFlg) {
        this.stopFlg = true;
        if (spLoginBtn.classList.contains('-active')) {
          if (spMenuBtn.classList.contains('-active')) {
            this.changeMenu('menu');
          } else {
            this.closeSpLoginMenu();
          }
        } else {
          this.openSpLoginMenu();
        }
      }
    });
    if (spLoginBtn) {
      spLoginBtn.addEventListener('click', e => {
        e.preventDefault();
        e.stopPropagation();
        this.debouncedOnSpLoginBtn();
      });
    }

    this.debouncedOnOverlayClick = _.debounce(() => {
      if (!this.stopFlg) {
        this.stopFlg = true;
        this.closeSpMenu();
        this.closeSpLoginMenu();
      }
    }, 0);
    if (spOverlayElm) {
      spOverlayElm.addEventListener('click', e => {
        e.preventDefault();
        e.stopPropagation();
        this.debouncedOnOverlayClick();
      });
    }

    // sp scrollevent
    this.onScroll = () => {
      if (this.timeout) {
        cancelAnimationFrame(this.timeout);
      }
      this.timeout = requestAnimationFrame(() => {
        self.scroll();
      });
    }
    if (this.ua.device() == "sp") {
      document.addEventListener(
        "scroll",
        () => {
          this.onScroll();
        },
        isPassive.check() ? { passive: true } : false
      );
      this.scrollPos = 0;
      this.onScroll();
    }

    // resize event
    this.debouncedOnResize = _.debounce(() => {
      if (self.openFlg) {
        const wh = window.innerHeight ? window.innerHeight : $(window).height();
        this.globalNavi.style.height = wh + "px";
      }
    }, 600);
    window.addEventListener('resize', e => {
      this.debouncedOnResize();
    }, isPassive.check() ? { passive: true } : false);
  }

  openSpMenu() {
    const spMenuBtn = this.container.getElementsByClassName('js-spMenuBtn')[0];
    const spMenuContent = this.container.getElementsByClassName('js-spGlobalNaviMenuContent')[0];

    const spLoginBtn = this.container.getElementsByClassName('js-spLoginBtn')[0];
    const spLoginContent = this.container.getElementsByClassName('js-spGlobalNaviLoginContent')[0];
    spLoginBtn.classList.remove('-active');
    spLoginContent.classList.remove('-active');

    if (!this.container.classList.contains('-spHeaderFixed')) {
      window.scrollTo(0, 0);
    }

    const wh = window.innerHeight ? window.innerHeight : $(window).height();
    this.globalNavi.style.height = wh + "px";

    let delay = 800;
    if (!this.openFlg) delay = 0;

    spMenuBtn.classList.add('-active');
    spMenuContent.classList.add('-active');
    document.body.classList.add('-spMenuOpen');
    this.container.classList.add('-spMenuOpen');

    this.openFlg = true;

    _.delay(() => {
      _.delay(() => {
        this.headerTop.classList.add('-show');
        this.globalNavi.classList.add('-show');
        spMenuBtn.classList.remove('-hide');
        this.stopFlg = false;

        // 初回表示時にカレント表示までスクロール
        if (!this.menuOpenFirstTimeFlg) {
          this.menuOpenFirstTimeFlg = true;
          const currentElm = this.globalNavi.getElementsByClassName("-current")[0];
          if (currentElm) {
            const currentElmRect = currentElm.getBoundingClientRect();
            const posTop = currentElmRect.top - this.headerTop.clientHeight;
            this.globalNavi.scrollTo(0, posTop);
          }
        }
      }, delay);
    }, 40);
  }

  closeSpMenu() {
    const spMenuBtn = this.container.getElementsByClassName('js-spMenuBtn')[0];
    const spMenuContent = this.container.getElementsByClassName('js-spGlobalNaviMenuContent')[0];

    this.globalNavi.style.height = "";

    spMenuBtn.classList.add('-hide');
    spMenuBtn.classList.remove('-active');

    this.headerTop.classList.remove('-show');
    this.globalNavi.classList.add('-hide');
    _.delay(() => {
      document.body.classList.remove('-spMenuOpen');
      this.container.classList.remove('-spMenuOpen');
      spMenuContent.classList.remove('-active');
      spMenuBtn.classList.remove('-hide');
      this.globalNavi.classList.remove('-show');
      this.globalNavi.classList.remove('-hide');
      this.openFlg = false;
      this.stopFlg = false;
    }, 800);
  }

  openSpLoginMenu() {
    const spLoginBtn = this.container.getElementsByClassName('js-spLoginBtn')[0];
    const spLoginContent = this.container.getElementsByClassName('js-spGlobalNaviLoginContent')[0];

    const spMenuContent = this.container.getElementsByClassName('js-spGlobalNaviMenuContent')[0];
    spMenuContent.classList.remove('-active');

    if (!this.container.classList.contains('-spHeaderFixed')) {
      window.scrollTo(0, 0);
    }

    const wh = window.innerHeight ? window.innerHeight : $(window).height();
    this.globalNavi.style.height = wh + "px";

    let delay = 800;

    if (!this.openFlg) delay = 0;
    this.openFlg = true;

    spLoginBtn.classList.add('-active');
    spLoginContent.classList.add('-active');

    document.body.classList.add('-spMenuOpen');
    this.container.classList.add('-spMenuOpen');

    _.delay(() => {
      _.delay(() => {
        this.headerTop.classList.add('-show');
        this.globalNavi.classList.add('-show');
        this.stopFlg = false;
      }, delay);
    }, 40);
  }

  closeSpLoginMenu() {
    const spLoginBtn = this.container.getElementsByClassName('js-spLoginBtn')[0];
    const spLoginContent = this.container.getElementsByClassName('js-spGlobalNaviLoginContent')[0];

    this.globalNavi.style.height = "";

    spLoginBtn.classList.remove('-active');

    this.headerTop.classList.remove('-show');
    this.globalNavi.classList.add('-hide');

    _.delay(() => {
      document.body.classList.remove('-spMenuOpen');
      this.container.classList.remove('-spMenuOpen');
      spLoginContent.classList.remove('-active');
      this.globalNavi.classList.remove('-show');
      this.globalNavi.classList.remove('-hide');
      this.openFlg = false;
      this.stopFlg = false;
    }, 800);
  }

  openSpNaviMenu(elm) {
    const contentElm = elm.nextElementSibling;
    if (contentElm) {
      if (contentElm.classList.contains('js-spNaviMenuContent')) {
        elm.classList.add('-active');
        $(contentElm).slideDown(400);
      }
    }
  }

  closeSpNaviMenu(elm) {
    const contentElm = elm.nextElementSibling;
    if (contentElm) {
      if (contentElm.classList.contains('js-spNaviMenuContent')) {
        elm.classList.remove('-active');
        $(contentElm).slideUp(400);
      }
    }
  }

  changeMenu(type) {
    const spMenuBtn = this.container.getElementsByClassName('js-spMenuBtn')[0];
    const spMenuContent = this.container.getElementsByClassName('js-spGlobalNaviMenuContent')[0];
    const spLoginBtn = this.container.getElementsByClassName('js-spLoginBtn')[0];
    const spLoginContent = this.container.getElementsByClassName('js-spGlobalNaviLoginContent')[0];

    if (type == 'menu') {
      spLoginBtn.classList.remove('-active');
      spLoginContent.classList.remove('-active');
      spMenuBtn.classList.add('-active');
      spMenuContent.classList.add('-active');
      this.stopFlg = false;
    } else if (type == 'login') {
      spMenuBtn.classList.add('-hide');
      spMenuBtn.classList.remove('-active');
      spMenuContent.classList.remove('-active');
      spLoginBtn.classList.add('-active');
      spLoginContent.classList.add('-active');
      _.delay(() => {
        spMenuBtn.classList.remove('-hide');
        this.stopFlg = false;
      }, 800);
    }
  }

  scroll() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const headerTopRect = this.headerTop.getBoundingClientRect();
    if (headerTopRect.height < scrollTop) {
      this.container.classList.add('-spHeaderFixed');
    } else {
      this.container.classList.remove('-spHeaderFixed');
    }
  }

  // jsonファイルからナビリスト生成
  initNaviList() {
    const naviListElm = document.getElementsByClassName('js-spNaviList')[0];
    if (!naviListElm) return false;

    // jsonFile 取得
    const jsonEvent = this.getJson.get(this.jsonFile);
    jsonEvent.done(data => {
      this.basePath = this.sitePath.getBasePath(data);
      const navList = this.createNaviList(data);
      if (navList) {
        naviListElm.appendChild(navList);

        const spNaviMenuElm = this.globalNavi.getElementsByClassName('js-spNaviMenu');
        Array.prototype.forEach.call(spNaviMenuElm, elm => {
          elm.addEventListener('click', e => {
            e.preventDefault();
            e.stopPropagation();
            this.debouncedOnSpNaviMenu(elm);
          });
        });
      }
    });
  }

  createNaviList(data) {
    const fragment = document.createDocumentFragment();

    _.each(data, item => {
      if (item.disabled == "") {
        const category = item.category;
        if (category != "other") {
          const naviElm = this.createNaviCategoryList(item);
          if (naviElm) {
            fragment.appendChild(naviElm);
          }
        }
      }
    });

    return fragment;
  }

  // カテゴリリスト生成
  createNaviCategoryList(item) {
    const fragment = document.createDocumentFragment();

    if (item.level == 1) {
      fragment.appendChild(this.createNaviCategoryListLevel1(item));
    }

    return fragment;
  }

  // 1階層 カテゴリリスト生成
  createNaviCategoryListLevel1(item) {
    const fragment = document.createDocumentFragment();

    const header = document.createElement("div");
    header.classList.add("spNaviList__header");
    if (item.child.length) {
      header.classList.add("js-spNaviMenu");
      if (this.basePath.level1 == item.url) {
        header.classList.add("-active");
      }
    }
    if (item.category == "person") {
      header.setAttribute("data-type", "person");
    }
    if (item.category == "company") {
      header.setAttribute("data-type", "corp");
    }
    header.textContent = item.name;

    fragment.appendChild(header);

    if (item.child.length) {
      const content = document.createElement("div");
      content.classList.add("spNaviList__content");
      content.classList.add("js-spNaviMenuContent");
      if (this.basePath.level1 == item.url) {
        content.style.display = "block";
      }

      content.appendChild(this.createNaviLinkLevel2(item, true));
      _.each(item.child, item2 => {
        if (item2.level == 2) {
          if (item2.disabled == "") {
            content.appendChild(this.createNaviCategoryListLevel2(item2));
          }
        }
      });

      fragment.appendChild(content);
    }

    return fragment;
  }

  // 2階層 カテゴリリスト生成
  createNaviCategoryListLevel2(item) {
    const fragment = document.createDocumentFragment();

    const childLength = this.getValidChildLength(item);

    if (!childLength) {
      fragment.appendChild(this.createNaviLinkLevel2(item, false));
    } else {
      const header = document.createElement("div");

      header.classList.add("spNaviList__header2");
      header.classList.add("js-spNaviMenu");
      if (this.basePath.level2 == item.url) {
        header.classList.add("-active");
      }

      if (item.spIcon) {
        const headerSpan1 = document.createElement("span");
        const headerSpan1img = document.createElement("img");
        headerSpan1img.src = item.spIcon;
        headerSpan1img.alt = "";
        headerSpan1.appendChild(headerSpan1img);
        const headerSpan2 = document.createElement("span");
        headerSpan2.textContent = item.name;

        header.appendChild(headerSpan1);
        header.appendChild(headerSpan2);
      } else {
        header.textContent = item.name;
      }

      fragment.appendChild(header);
    }

    if (childLength) {
      const content = document.createElement("div");
      content.classList.add("spNaviList__content2");
      content.classList.add("js-spNaviMenuContent");
      if (this.basePath.level2 == item.url) {
        content.style.display = "block";
      }
      if (item.spBg) {
        content.style.backgroundImage = "url(" + item.spBg + ")";
      }

      content.appendChild(this.createNaviLinkLevel3(item, true));
      _.each(item.child, item2 => {
        if (item2.level == 3) {
          if (item2.disabled == "") {
            content.appendChild(this.createNaviCategoryListLevel3(item2));
          }
        }
      });

      fragment.appendChild(content);
    }

    return fragment;
  }

  // 3階層 カテゴリリスト生成
  createNaviCategoryListLevel3(item) {
    const fragment = document.createDocumentFragment();

    const childLength = this.getValidChildLength(item);

    if (!childLength) {
      fragment.appendChild(this.createNaviLinkLevel3(item, false));
    } else {
      const header = document.createElement("div");

      header.classList.add("spNaviList__header3");
      header.classList.add("js-spNaviMenu");
      if (this.basePath.level3 == item.url) {
        header.classList.add("-active");
      }
      header.textContent = item.name;

      fragment.appendChild(header);
    }

    if (childLength) {
      const content = document.createElement("div");
      content.classList.add("spNaviList__content3");
      content.classList.add("js-spNaviMenuContent");
      if (this.basePath.level3 == item.url) {
        content.style.display = "block";
      }

      content.appendChild(this.createNaviLinkLevel4(item, false));
      const ul = document.createElement("ul");
      _.each(item.child, item2 => {
        if (item2.level == 4) {
          if (item2.disabled == "") {
            ul.appendChild(this.createNaviCategoryListLevel4(item2));
          }
        }
      });
      content.appendChild(ul);

      fragment.appendChild(content);
    }

    return fragment;
  }

  // 有効な子要素の数を返却
  getValidChildLength(data) {
    let childLength = 0;
    if (data.child.length) {
      _.each(data.child, item => {
        if (item.disabled == "") {
          childLength++;
        }
      });
    }
    return childLength;
  }


  // 4階層 カテゴリリスト生成
  createNaviCategoryListLevel4(item) {
    const li = document.createElement("li");
    li.appendChild(this.createNaviLinkLevel4(item));
    return li;
  }

  // 2階層 リンク要素生成
  createNaviLinkLevel2(item, topFlg) {
    const link = document.createElement("a");

    link.href = item.url;
    if (item.level == 1) {
      if (
        this.basePath.level1 == item.url &&
        this.basePath.level2 == "" &&
        this.basePath.level3 == "" &&
        this.basePath.level4 == ""
      ) {
        link.classList.add("-current");
      }
    } else if (item.level == 2) {
      if (
        this.basePath.level2 == item.url &&
        this.basePath.level3 == "" &&
        this.basePath.level4 == ""
      ) {
        link.classList.add("-current");
      }
    }

    if (topFlg) {
      link.textContent = item.name + "トップ";
    } else {
      link.textContent = item.name;
    }

    if (item.blank) {
      link.target = "_blank";
      link.appendChild(this.createIconElm("blank", "", item.url));
    }
    if (item.login) {
      link.target = "_blank";
      link.appendChild(this.createIconElm("login", "", item.url));
    }
    if (item.pdf) {
      link.target = "_blank";
      link.appendChild(this.createIconElm("pdf", "", item.url));
    }

    return link
  }

  // 3階層 リンク要素生成
  createNaviLinkLevel3(item, topFlg) {
    const link = document.createElement("a");
    let currentFlg = false;

    link.href = item.url;
    if (item.level == 2) {
      if (
        this.basePath.level2 == item.url &&
        this.basePath.level3 == "" &&
        this.basePath.level4 == ""
      ) {
        link.classList.add("-current");
        currentFlg = true;
      }
    } else if (item.level == 3) {
      if (
        this.basePath.level3 == item.url &&
        this.basePath.level4 == ""
      ) {
        link.classList.add("-current");
        currentFlg = true;
      }
    }

    if (topFlg) {
      if (item.name.toLowerCase() == "english") {
        link.textContent = item.name + " Top";
      } else {
        link.textContent = item.name + "トップ";
      }
    } else {
      link.textContent = item.name;
    }

    const white = currentFlg ? "" : "white";
    if (item.blank) {
      link.target = "_blank";
      link.appendChild(this.createIconElm("blank", white, item.url));
    }
    if (item.login) {
      link.target = "_blank";
      link.appendChild(this.createIconElm("login", white, item.url));
    }
    if (item.pdf) {
      link.target = "_blank";
      link.appendChild(this.createIconElm("pdf", white, item.url));
    }

    return link
  }

  // 4階層 リンク要素生成
  createNaviLinkLevel4(item) {
    const link = document.createElement("a");
    let currentFlg = false;

    link.href = item.url;
    if (item.level == 3) {
      if (
        this.basePath.level3 == item.url &&
        this.basePath.level4 == ""
      ) {
        link.classList.add("-current");
        currentFlg = true;
      }
    } else if (item.level == 4) {
      if (this.basePath.level4 == item.url) {
        link.classList.add("-current");
        currentFlg = true;
      }
    }
    link.textContent = item.name;

    const white = currentFlg ? "" : "white";
    if (item.blank) {
      link.target = "_blank";
      link.appendChild(this.createIconElm("blank", white, item.url));
    }
    if (item.login) {
      link.target = "_blank";
      link.appendChild(this.createIconElm("login", white, item.url));
    }
    if (item.pdf) {
      link.target = "_blank";
      link.appendChild(this.createIconElm("pdf", white, item.url));
    }

    return link
  }

  createIconElm(type, white, link) {
    const img = document.createElement("img");
    if (type == "blank") {
      img.classList.add("spNaviList__iconBlank");
      if (white) {
        img.src = "/h_common/css/image/icon_blank_white.png";
      } else {
        img.src = "/h_common/css/image/icon_blank.png";
      }
    } else if (type == "login") {
      img.classList.add("spNaviList__iconBlank");
      if (white) {
        img.src = "/h_common/css/image/icon_ex_white.png";
      } else {
        img.src = "/h_common/css/image/icon_ex.png";
      }
    } else if (type == "pdf") {
      img.classList.add("spNaviList__iconPdf");
      img.src = "/h_common/css/image/icon_pdf.png";
    }
    return img;
  }
}
