import _ from "underscore";

export default class StorageLib {
  constructor() {}

  getStorage(key) {
    const value = localStorage.getItem(key);
    if (!value) return false;
    return value;
  }

  // ストレージに保存
  setStorage(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }
}
