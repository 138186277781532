import $ from "jquery";
import _ from "underscore";
import IsPassiveSupport from "../lib/IsPassiveSupport";

export default class GotoTop {
  constructor() {
    this.init();
  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    const self = this;
    const isPassive = new IsPassiveSupport();
    const trigger = document.getElementsByClassName('js-gotoTop')[0];

    this.debouncedOnClick = _.debounce(() => {
      $('body, html').animate({ scrollTop: 0 }, 400);
    }, 0);
    trigger.addEventListener('click', e => {
      e.preventDefault();
      e.stopPropagation();
      this.debouncedOnClick();
    });

    this.onScroll = () => {
      if (this.timeout) {
        cancelAnimationFrame(this.timeout);
      }
      this.timeout = requestAnimationFrame(() => {
        self.scroll();
      });
    }
    document.addEventListener(
      "scroll",
      () => {
        this.onScroll();
      },
      isPassive.check() ? { passive: true } : false
    );
    this.scrollPos = 0;
    this.onScroll();
  }

  scroll() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const gotoTopElm = document.getElementsByClassName('js-gotoTop')[0];

    if (scrollTop < window.innerHeight) {
      gotoTopElm.classList.add('-hide');
    } else {
      gotoTopElm.classList.remove('-hide');
    }
  }
}
