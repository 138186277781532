import $ from "jquery";
import _ from "underscore";

export default class AnchorLink {
  constructor() {
    this.breakPoint = 768;
    this.init();
  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    const anchorTrigger = document.getElementsByClassName('js-anchorLink');

    this.debouncedOnClick = _.debounce(elm => {
      this.move(elm);
    }, 0);
    if (anchorTrigger.length) {
      Array.prototype.forEach.call(anchorTrigger, elm => {
        elm.addEventListener('click', e => {
          e.preventDefault();
          e.stopPropagation();
          this.debouncedOnClick(elm);
        });
      });
    }
  }

  move(elm) {
    const href = elm.attributes["href"].value;
    const target = $(href);
    const pos = this.getPos(target, elm);
    $('body,html').animate({ scrollTop: pos }, 400, 'swing');
  }

  getPos(target, elm) {
    let pos;
    let offset = 0;
    if (window.innerWidth <= this.breakPoint) {
      const offsetSp = elm.getAttribute("data-offset-sp");
      if (offsetSp) {
        offset = offsetSp;
      }
    } else {
      const offsetPc = elm.getAttribute("data-offset-pc");
      let offset = 0;
      if (offsetPc) {
        offset = offsetPc;
      }
    }

    pos = target.offset().top + parseInt(offset);

    return pos;
  }
}

