import _ from "underscore";
import StorageLib from "../lib/StorageLib";
import HeightSet from "../subPage/HeightSet";
import AllHeightSet from "../subPage/AllHeightSet";

export default class headerMenuTop {
  constructor() {
    this.container = document.getElementsByClassName('js-header')[0];
    this.storageName = "fontsize";
    this.storageLib = new StorageLib();
    this.heightSet = new HeightSet();
    this.allHeightSet = new AllHeightSet();
    this.lockFlg = false;
    this.init();
  }

  init() {
    this.bindEvents();
    this.initFontSize();
  }

  bindEvents() {
    const self = this;
    const searchBtnElm = this.container.getElementsByClassName('js-headerSearch')[0];
    const fontChangeBtnElm = this.container.getElementsByClassName('js-fontChangeBtn')[0];
    const rsLinkBtnElm = this.container.getElementsByClassName('js-rsLinkBtn')[0];

    // Search Btn
    this.debouncedOnSearchBtn = _.debounce(() => {
      if (!this.lockFlg) {
        this.lockFlg = true;
        if (searchBtnElm.parentNode.classList.contains('-active')) {
          this.searchClose();
        } else {
          this.searchOpen();
        }
      }
    }, 0);
    if (searchBtnElm) {
      searchBtnElm.addEventListener('click', e => {
        e.preventDefault();
        e.stopPropagation();
        this.debouncedOnSearchBtn();
      });
    }

    // fontChange Btn
    this.debouncedOnFontChangeBtn = _.debounce(() => {
      if (!this.lockFlg) {
        this.lockFlg = true;
        if (fontChangeBtnElm.classList.contains('-active')) {
          this.fontChangeClose();
        } else {
          this.fontChangeOpen();
        }
      }
    }, 0);
    if (fontChangeBtnElm) {
      fontChangeBtnElm.addEventListener('click', e => {
        e.preventDefault();
        e.stopPropagation();
        this.debouncedOnFontChangeBtn();
      });
    }

    // fontChangeTarget Btn
    const fontChangeTarget = this.container.getElementsByClassName('js-fontChangeTarget');
    this.debouncedOnFontChangeTargetBtn = _.debounce(elm => {
      this.changeFontSize(elm);
    }, 0);
    Array.prototype.forEach.call(fontChangeTarget, elm => {
      elm.addEventListener('click', e => {
        e.preventDefault();
        e.stopPropagation();
        this.debouncedOnFontChangeTargetBtn(elm);
      });
    });

    // rsLink Btn
    this.debouncedOnRsLinkChangeBtn = _.debounce(() => {
      if (!this.lockFlg) {
        this.lockFlg = true;
        if (rsLinkBtnElm.parentNode.classList.contains('-active')) {
          this.rsLinkClose();
        } else {
          this.rsLinkOpen();
        }
      }
    }, 0);
    if (rsLinkBtnElm) {
      rsLinkBtnElm.addEventListener('click', e => {
        e.preventDefault();
        e.stopPropagation();
        this.debouncedOnRsLinkChangeBtn();
      });
    }

    const rsLinkElm = document.getElementById('xp1');
    if (rsLinkElm) {
      const observer = new MutationObserver(() => {
        const style = window.getComputedStyle(rsLinkElm, null);
        if (rsLinkBtnElm.parentNode.classList.contains('-active')) {
          if (style.display == 'none') {
            self.rsLinkClose(true);
          }
        }
      });
      observer.observe(rsLinkElm, {
        attributes: true,
        childList: true,
        characterData: true
      });
    }
  }

  searchOpen() {
    const searchBtnElm = this.container.getElementsByClassName('js-headerSearch')[0];
    const searchContentElm = this.container.getElementsByClassName('js-headerSearchContent')[0];
    this.fontChangeClose();
    this.rsLinkClose();
    searchBtnElm.parentNode.classList.add('-active');
    searchContentElm.classList.add('-active');
    _.delay(() => {
      this.lockFlg = false;
      this.container.classList.add('-active');
    }, 100);
  }

  searchClose() {
    const searchBtnElm = this.container.getElementsByClassName('js-headerSearch')[0];
    const searchContentElm = this.container.getElementsByClassName('js-headerSearchContent')[0];

    if (!(searchBtnElm && searchContentElm)) return false;

    searchBtnElm.parentNode.classList.remove('-active');
    searchContentElm.classList.remove('-active');
    _.delay(() => {
      this.lockFlg = false;
      this.container.classList.remove('-active');
    }, 100);
  }

  fontChangeOpen() {
    const fontChangeBtnElm = this.container.getElementsByClassName('js-fontChangeBtn')[0];
    const fontChangeContentElm = this.container.getElementsByClassName('js-fontChangeContent')[0];
    this.searchClose();
    this.rsLinkClose();
    fontChangeBtnElm.classList.add('-active');
    fontChangeContentElm.classList.add('-active');
    _.delay(() => {
      this.lockFlg = false;
      this.container.classList.add('-active');
    }, 100);
  }

  fontChangeClose() {
    const fontChangeBtnElm = this.container.getElementsByClassName('js-fontChangeBtn')[0];
    const fontChangeContentElm = this.container.getElementsByClassName('js-fontChangeContent')[0];

    if (!(fontChangeBtnElm && fontChangeContentElm)) return false;

    fontChangeBtnElm.classList.remove('-active');
    fontChangeContentElm.classList.remove('-active');
    _.delay(() => {
      this.lockFlg = false;
      this.container.classList.remove('-active');
    }, 100);
  }

  changeFontSize(elm) {
    const fontChangeTarget = this.container.getElementsByClassName('js-fontChangeTarget');
    const type = elm.getAttribute('data-type');
    const fontChangeElm = document.querySelectorAll('[data-sizeL]');
    const path = location.pathname.substr(0, location.pathname.lastIndexOf("/")) + "/";

    Array.prototype.forEach.call(fontChangeTarget, elm2 => {
      const type2 = elm2.getAttribute('data-type');
      if (type == type2) {
        elm2.classList.add('-active');
      } else {
        elm2.classList.remove('-active');
      }
    });

    if (type == 'normal') {
      if (path == "" || path == "/") {
        document.body.style.fontSize = '';
      } else {
        document.documentElement.style.fontSize = '';
      }
      document.body.classList.remove('-fontSizeL')
      if (fontChangeElm.length) {
        Array.prototype.forEach.call(fontChangeElm, elm => {
          elm.style.fontSize = '';
        });
      }
    } else if (type == 'big') {
      if (path == "" || path == "/") {
        document.body.style.fontSize = '112%';
      } else {
        document.documentElement.style.fontSize = '112%';
      }
      document.body.classList.add('-fontSizeL')
      if (fontChangeElm.length) {
        Array.prototype.forEach.call(fontChangeElm, elm => {
          const size = elm.getAttribute('data-sizeL');
          elm.style.fontSize = size + 'px';
        });
      }
    }

    //各エレメントの高さ調整
    this.heightSet.bindEvents();
    this.allHeightSet.bindEvents();

    // save localstorage
    this.storageLib.setStorage(this.storageName, type);
  }

  rsLinkOpen() {
    const rsLinkBtnElm = this.container.getElementsByClassName('js-rsLinkBtn')[0];
    const rsLinkContentElm = this.container.getElementsByClassName('js-rsLinkContent')[0];
    this.searchClose();
    this.fontChangeClose();
    rsLinkBtnElm.parentNode.classList.add('-active');
    rsLinkContentElm.classList.add('-active');
    _.delay(() => {
      this.lockFlg = false;
      this.container.classList.add('-active');
    }, 100);
  }

  rsLinkClose(flg) {
    const rsLinkBtnElm = this.container.getElementsByClassName('js-rsLinkBtn')[0];
    const rsLinkContentElm = this.container.getElementsByClassName('js-rsLinkContent')[0];

    if (!(rsLinkBtnElm && rsLinkContentElm)) return false;

    rsLinkBtnElm.parentNode.classList.remove('-active');

    if (flg) {
      rsLinkContentElm.classList.add('-hide');
      _.delay(() => {
        rsLinkContentElm.classList.remove('-hide');
        rsLinkContentElm.classList.remove('-active');
      }, 60);
    } else {
      rsLinkContentElm.classList.remove('-active');
    }
    _.delay(() => {
      this.lockFlg = false;
      this.container.classList.remove('-active');
    }, 100);
  }

  closeAll() {
    this.searchClose();
    this.fontChangeClose();
  }

  // localstorageに値があれば文字サイズ変更
  initFontSize() {
    const fontsize = JSON.parse(this.storageLib.getStorage(this.storageName));

    if (fontsize) {
      const fontChangeTarget = this.container.getElementsByClassName('js-fontChangeTarget');
      if (fontChangeTarget.length) {
        Array.prototype.forEach.call(fontChangeTarget, elm => {
          const type = elm.getAttribute('data-type');
          if (type == fontsize) {
            this.changeFontSize(elm);
          }
        });
        //各エレメントの高さ調整
        this.heightSet.bindEvents();
      }
    }
  }
}

