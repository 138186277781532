import _ from "underscore";
import UA from "../lib/UA";
import IsPassiveSupport from "../lib/IsPassiveSupport";
import SitePath from "../lib/SitePath";
import GetJson from "../lib/GetJson";

export default class GlobalNavi {
  constructor() {
    this.container = document.getElementsByClassName('js-header')[0];
    this.globalNavi = this.container.getElementsByClassName('js-globalNavi')[0];
    this.stopFlg = false;
    this.openFlg = false;
    this.timeout;
    this.breakPoint = 768;
    this.ww = window.innerWidth ? window.innerWidth : $(window).width();
    this.ua = new UA();
    this.sitePath = new SitePath();
    this.getJson = new GetJson();
    this.jsonFile = this.sitePath.getJsonPath();
    this.basePath;
    this.init();
  }

  init() {
    const jsonEvent = this.getJson.get(this.jsonFile);
    jsonEvent.done(data => {
      this.basePath = this.sitePath.getBasePath(data);
      this.setCurrent();
    });

    this.bindEvents();
  }

  bindEvents() {
    const self = this;
    const isPassive = new IsPassiveSupport();

    if (!this.globalNavi) return false;

    const naviMenuBtnElm = this.globalNavi.getElementsByClassName('js-naviMenuBtn');
    const naviSubMenuElm = this.globalNavi.getElementsByClassName('js-naviSubMenu');
    const naviMenuElm = this.globalNavi.getElementsByClassName('js-globalNaviMenu')[0];
    const overlayElm = document.getElementsByClassName('js-headerOverlay')[0];

    this.debouncedOnNaviMenuBtn = _.debounce(elm => {
      if (!this.stopFlg) {
        this.stopFlg = true;
        this.changeMenu(elm);
      }
    }, 0);
    if (naviMenuBtnElm.length) {
      Array.prototype.forEach.call(naviMenuBtnElm, elm => {
        elm.addEventListener('click', e => {
          e.preventDefault();
          e.stopPropagation();
          this.debouncedOnNaviMenuBtn(elm);
        });
      });
    }

    this.debouncedOnNaviSubMenuClick = _.debounce(elm => {
      if (!this.stopFlg) {
        this.stopFlg = true;
        if (elm.classList.contains('-active')) {
          this.closeSubMenu();
        } else {
          this.openSubMenu(elm);
        }
      }
    }, 0);

    if (naviSubMenuElm.length) {
      Array.prototype.forEach.call(naviSubMenuElm, elm => {
        elm.addEventListener('click', e => {
          e.preventDefault();
          e.stopPropagation();
          this.debouncedOnNaviSubMenuClick(elm);
        });
      });
    }

    this.debouncedOnOverlayClick = _.debounce(() => {
      if (!this.stopFlg) {
        this.stopFlg = true;
        this.overlayClick();
      }
    }, 0);
    if (overlayElm) {
      overlayElm.addEventListener('click', e => {
        e.preventDefault();
        e.stopPropagation();
        this.debouncedOnOverlayClick();
      });
    }

    if (naviMenuElm) {
      naviMenuElm.addEventListener('click', e => {
        this.debouncedOnOverlayClick();
      });
    }

    // pc scrollevent
    this.onScroll = () => {
      if (this.timeout) {
        cancelAnimationFrame(this.timeout);
      }
      this.timeout = requestAnimationFrame(() => {
        self.scroll();
      });
    }
    if (this.ua.device() == "other") {
      document.addEventListener(
        "scroll",
        () => {
          this.onScroll();
        },
        isPassive.check() ? { passive: true } : false
      );
      this.scrollPos = 0;
      this.onScroll();
    }

    // resize event
    this.debouncedOnResize = _.debounce(() => {
      this.ww = window.innerWidth ? window.innerWidth : $(window).width();
    }, 600);
    window.addEventListener('resize', e => {
      this.debouncedOnResize();
    }, isPassive.check() ? { passive: true } : false);
  }

  changeMenu(elm) {
    if (!this.globalNavi) return false;

    const naviMenuBtnElm = this.globalNavi.getElementsByClassName('js-naviMenuBtn');
    const naviContentElm = this.globalNavi.getElementsByClassName('js-naviContent');
    const type = elm.getAttribute('data-type');
    let loginFlg = false;

    Array.prototype.forEach.call(naviMenuBtnElm, elm2 => {
      const type2 = elm2.getAttribute('data-type');
      if (type == 'login') {
        if (type == type2) {
          loginFlg = true;
          if (elm2.classList.contains('-active')) {
            elm2.classList.remove('-active');
          } else {
            elm2.classList.add('-active');
          }
        }
      } else {
        if (type != type2) {
          elm2.classList.remove('-active');
          elm2.classList.add('-back');
        }
      }
    });

    Array.prototype.forEach.call(naviContentElm, elm2 => {
      const type2 = elm2.getAttribute('data-type');
      if (type == type2) {
        if (type == 'login') {
          if (elm2.classList.contains('-active')) {
            elm2.classList.remove('-active');
          } else {
            elm2.classList.add('-active');
          }
        } else {
          elm2.classList.add('-active');
        }
      } else {
        elm2.classList.remove('-active');
      }
    });

    if (loginFlg && !elm.classList.contains('-active')) {
      const naviMenuBtnElm = this.globalNavi.getElementsByClassName('js-naviMenuBtn');
      const naviContentElm = this.globalNavi.getElementsByClassName('js-naviContent');
      let newType;
      Array.prototype.forEach.call(naviMenuBtnElm, elm => {
        if (elm.classList.contains('-active')) {
          newType = elm.getAttribute('data-type');
        }
      });
      Array.prototype.forEach.call(naviContentElm, elm => {
        const type = elm.getAttribute('data-type');
        if (type == newType) {
          elm.classList.add('-active');
        }
      });
      this.openFlg = false;
      this.checkSubMenu();
    } else {
      elm.classList.remove('-back');
      elm.classList.add('-active');

      if (loginFlg) {
        // close sub menu
        const naviSubMenuElm = this.globalNavi.getElementsByClassName('js-naviSubMenu');
        const naviSubContentElm = this.globalNavi.getElementsByClassName('js-naviSubContent');
        Array.prototype.forEach.call(naviSubMenuElm, elm => {
          elm.classList.remove('-active');
        });
        Array.prototype.forEach.call(naviSubContentElm, elm => {
          elm.classList.remove('-active');
        });
      }

      this.checkSubMenu();
    }
  }

  openSubMenu(elm) {
    this.openFlg = true;
    const num = elm.getAttribute('data-num');

    const targetContent = (() => {
      let targetElm;

      const naviContentElm = this.globalNavi.getElementsByClassName('js-naviContent');
      Array.prototype.forEach.call(naviContentElm, elm => {
        if (elm.classList.contains('-active')) {
          targetElm = elm;
        }
      });

      return targetElm;
    })();

    if (targetContent) {
      const naviSubMenuElm = targetContent.getElementsByClassName('js-naviSubMenu');
      const naviSubContentElm = targetContent.getElementsByClassName('js-naviSubContent');

      Array.prototype.forEach.call(naviSubMenuElm, elm2 => {
        const num2 = elm2.getAttribute('data-num');
        if (num == num2) {
          elm2.classList.add('-active');
        } else {
          elm2.classList.remove('-active');
        }
      });

      Array.prototype.forEach.call(naviSubContentElm, elm2 => {
        const num2 = elm2.getAttribute('data-num');
        if (num == num2) {
          elm2.classList.add('-active');
        } else {
          elm2.classList.remove('-active');
        }
      });

      this.checkSubMenu();
    } else {
      this.checkSubMenu();
    }
  }

  closeSubMenu() {
    this.openFlg = false;

    const targetContent = (() => {
      let targetElm;

      const naviContentElm = this.globalNavi.getElementsByClassName('js-naviContent');
      Array.prototype.forEach.call(naviContentElm, elm => {
        if (elm.classList.contains('-active')) {
          targetElm = elm;
        }
      });

      return targetElm;
    })();

    if (targetContent) {
      const naviSubMenuElm = targetContent.getElementsByClassName('js-naviSubMenu');
      const naviSubContentElm = targetContent.getElementsByClassName('js-naviSubContent');

      Array.prototype.forEach.call(naviSubContentElm, elm => {
        elm.classList.remove('-active');
      });

      _.delay(() => {
        Array.prototype.forEach.call(naviSubMenuElm, elm => {
          if (!this.openFlg) {
            elm.classList.remove('-active');
          }
        });
        this.checkSubMenu();
      }, 200);
    } else {
      this.checkSubMenu();
    }
  }

  // check menu open
  checkSubMenu() {
    let menuOpenFlg = false;
    let activeElm = (() => {
      const naviMenuBtnElm = this.globalNavi.getElementsByClassName('js-naviMenuBtn');
      let targetElm;

      Array.prototype.forEach.call(naviMenuBtnElm, elm => {
        if (elm.classList.contains('-active')) {
          targetElm = elm;
        }
      });

      return targetElm;
    })();

    if (activeElm) {
      const type = activeElm.getAttribute('data-type');
      if (type == 'login') {
        menuOpenFlg = true;
      } else {
        const activeFlg = (() => {
          let flg = false;
          let targetElm;

          const naviContentElm = this.globalNavi.getElementsByClassName('js-naviContent');
          Array.prototype.forEach.call(naviContentElm, elm => {
            if (elm.classList.contains('-active')) {
              targetElm = elm;
            }
          });

          const targetMenuElm = targetElm.getElementsByClassName('js-naviSubMenu');
          Array.prototype.forEach.call(targetMenuElm, elm => {
            if (elm.classList.contains('-active')) {
              flg = true;
            }
          });

          return flg;
        })();

        if (activeFlg) {
          menuOpenFlg = true;
        }
      }
    }

    const overlayElm = document.getElementsByClassName('js-headerOverlay')[0];
    if (menuOpenFlg) {
      if (overlayElm.classList.contains('-active')) {
        this.stopFlg = false;
        if (this.openFlg) {
          overlayElm.classList.add('-active');
          overlayElm.classList.add('-show');
        }
      } else {
        overlayElm.classList.add('-active');
        _.delay(() => {
          overlayElm.classList.add('-show');
          _.delay(() => {
            this.stopFlg = false;
          }, 200);
        }, 10);
      }
    } else {
      if (!this.openFlg) {
        overlayElm.classList.remove('-show');
        _.delay(() => {
          overlayElm.classList.remove('-active');
          this.stopFlg = false;
          if (this.openFlg) {
            overlayElm.classList.add('-active');
            overlayElm.classList.add('-show');
          }
        }, 200);
      } else {
        this.stopFlg = false;
      }
    }
  }

  overlayClick() {
    const naviMenuBtnElm = this.globalNavi.getElementsByClassName('js-naviMenuBtn');

    const activeType = (() => {
      let type;
      Array.prototype.forEach.call(naviMenuBtnElm, elm => {
        if (elm.classList.contains('-active')) {
          type = elm.getAttribute('data-type');
        }
      });
      return type;
    })();

    if (activeType == 'login') {
      const loginMenuElm = (() => {
        let targetElm;
        Array.prototype.forEach.call(naviMenuBtnElm, elm => {
          const type = elm.getAttribute('data-type');
          if (type == activeType) {
            targetElm = elm;
          }
        });
        return targetElm;
      })();
      this.changeMenu(loginMenuElm);
    } else {
      this.closeSubMenu();
    }
  }

  scroll() {
    if (this.ww < this.breakPoint) return false;

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollX = window.scrollX || window.pageXOffset;
    const fixedElm = this.container.getElementsByClassName('js-globalNaviFixed')[0];
    const naviElm = this.container.getElementsByClassName('js-globalNavi')[0];
    const naviContentElm = this.container.getElementsByClassName('js-naviContent')[0];

    if (naviElm && naviContentElm) {
      const naviElmRect = naviElm.getBoundingClientRect();
      const naviContentElmRect = naviContentElm.getBoundingClientRect();
      const startPos = (() => {
        let pos = 0;
        if (naviElm.classList.contains('-open')) {
          pos = this.container.clientHeight - naviElmRect.height;
        } else {
          pos = this.container.clientHeight - naviContentElmRect.height;
        }
        return pos;
      })();

      if (scrollTop < startPos) {
        this.container.style.height = "";
        naviElm.classList.remove('-fixed');
        naviElm.classList.remove('-open');
        fixedElm.setAttribute('style', '');
        this.scrollPos = scrollTop;
      } else {
        this.container.style.height = this.container.clientHeight + "px";
        naviElm.classList.add('-fixed');
      }

      if (naviElm.classList.contains('-fixed')) {
        if (Math.abs(scrollTop - this.scrollPos) > 100) {
          if (this.scrollPos < scrollTop) {
            // down scroll
            naviElm.classList.remove('-open');
          } else {
            // up scroll
            naviElm.classList.add('-open');
          }
          this.scrollPos = scrollTop;
        }

        fixedElm.setAttribute('style', 'transform: translateX(-' + scrollX + 'px');
      }
    }
  }

  setCurrent(data) {
    if (!this.globalNavi) return false;

    const naviSubMenuElm = this.globalNavi.getElementsByClassName('js-naviSubMenu');
    const naviSubContentElm = this.globalNavi.getElementsByClassName('js-naviSubContent');
    const naviSubMenuLinkElm = this.globalNavi.getElementsByClassName('js-naviSubMenuLink');

    if (naviSubMenuElm.length && naviSubContentElm.length) {
      let currentNum = "";

      Array.prototype.forEach.call(naviSubContentElm, elm => {
        const num = elm.getAttribute("data-num");
        const topLink = elm.getElementsByClassName("globalNaviSubMenu__main")[0]
                           .getElementsByTagName("a")[0];
        if (topLink) {
          const href = topLink.getAttribute("href");
          if (this.basePath.level2 == href) {
            currentNum = num;
          }
        }
      });

      Array.prototype.forEach.call(naviSubMenuElm, elm => {
        const num = elm.getAttribute("data-num");
        if (num == currentNum) {
          elm.classList.add("-current");
        }
      });
    }

    if (naviSubMenuLinkElm.length) {
      Array.prototype.forEach.call(naviSubMenuLinkElm, elm => {
        const linkElm = elm.getElementsByTagName("a")[0];
        if (linkElm) {
          const href = linkElm.getAttribute("href");
          if (this.basePath.level2 == href) {
            elm.classList.add("-current");
          }
        }
      });
    }
  }
}
